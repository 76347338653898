import { ErrorEntity } from "@foudroyer/interfaces"
import { RankingStatsForFrontend } from "../entities/RankingWebsiteEntity"
import { IRepositoryResponse } from "../interfaces/IApiResponse"
import {
  ISpreadRepository,
  StatsResponse,
} from "../interfaces/ISpreadRepository"
import { AnalyticsWebsite } from "../redux/analytics/reducers"

export class InMemorySpreadRepository implements ISpreadRepository {
  private stats: RankingStatsForFrontend = {
    global: {
      click_through_rate: 0,
      clicks: 0,
      impressions: 0,
      position: 0,
      previous_clicks: 0,
      previous_click_through_rate: 0,
      previous_impressions: 0,
      previous_position: 0,
    },
    date: [],
    sources: [],
    devices: [],
    query: [],
    countries: [],
    pages: [],
    google: [],
    yandex: [],
    bing: [],
  }

  private websites: AnalyticsWebsite[] = []

  __storeWebsites(websites: AnalyticsWebsite[]) {
    this.websites = websites
  }

  __storeStats(stats: RankingStatsForFrontend) {
    this.stats = stats
  }

  async fetch(): Promise<StatsResponse> {
    if (!this.stats) {
      return {
        error: true,
        code: ErrorEntity.WEBSITE_NOT_FOUND,
      } as StatsResponse
    }

    return {
      error: false,
      body: {
        stats: this.stats,
        websites: [],
        sources: {
          google: { clicks: 0, impressions: 0, activated: false },
          bing: { clicks: 0, impressions: 0, activated: false },
          yandex: { clicks: 0, impressions: 0, activated: false },
        },
      },
    }
  }

  fetchLadder(): Promise<any> {
    throw new Error("Method not implemented.")
  }

  async fetch_websites(): Promise<IRepositoryResponse<AnalyticsWebsite[]>> {
    return {
      error: false,
      body: this.websites,
    }
  }
}
