import { localStorageKeys } from "@/constants/localStorageKeys"
import {
  RankingStatEntity,
  RankingStatsForFrontend,
} from "@/entities/RankingWebsiteEntity"
import { getFiltersFromUrl } from "@/utils/getFiltersFromUrl"
import { getWebsiteIdFromUrl } from "@/utils/getWebsiteIdFromUrl"
import { moveDate } from "@/utils/moveDateFromUrl"
import { normalizeUrl } from "@/utils/normalizeUrl"
import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import { json2csv } from "json-2-csv"

import { actions } from "../actions"
import { AsyncThunkConfig } from "../store"
import { AnalyticsWebsite } from "./reducers"

export const RankingSetToastAccepted = createAction<{ value: boolean }>(
  "RankingSetAnalyticsToastDataLateAccepted"
)

export const AnalyticsStorePreviousUrl = createAction<{ value: string | null }>(
  "AnalyticsStorePreviousUrl"
)

export const AnalyticsSetAnalyticsComingSoonModalIsOpen = createAction<{
  value: boolean
}>("AnalyticsSetAnalyticsComingSoonModalIsOpen")

export const AnalyticsStoreIsFinishedStatus = createAction<{ value: boolean }>(
  "AnalyticsStoreIsFinishedStatus"
)

export const RankingSetFetching =
  createAction<{ value: boolean }>("RankingSetFetching")

export const RankingStoreFilterQuery = createAction<{ value: string }>(
  "RankingStoreFilterQuery"
)

export const RankingStoreOrderBy = createAction<{
  value: "clicks" | "impressions" | "position" | "click_through_rate"
}>("RankingStoreOrderBy")

export const RankingStoreStats = createAction<{
  value: RankingStatsForFrontend
}>("RankingStoreStats")

export const RankingStoreStatsHistogram = createAction<{
  value: RankingStatEntity[]
}>("RankingStoreStatsHistogram")

export const RankingHistogramModalSetFetching = createAction<{
  value: boolean
}>("RankingHistogramModalSetFetching")

export const RankingHistogramModalSetToggle = createAction<{
  value: boolean
}>("RankingHistogramModalSetToggle")

export const RankingSetHistogramView = createAction<{
  value: any // Replace with proper type from RankingOrderByType
}>("RankingSetHistogramView")

export const RankingHistogramModalSetType = createAction<{
  value: "device" | "query" | "country" | "source" | "page"
}>("RankingHistogramModalSetType")

export const AnalyticsSetAnalyticsDiscoverModalIsFetching = createAction<{
  value: boolean
}>("AnalyticsSetAnalyticsDiscoverModalIsFetching")

export const AnalyticsSetAnalyticsDiscoverModalIsOpen = createAction<{
  value: boolean
}>("AnalyticsSetAnalyticsDiscoverModalIsOpen")

export const $fetch = createAsyncThunk<
  void,
  { force?: boolean },
  AsyncThunkConfig
>("analytics/fetch", async ({ force }, { dispatch, getState, extra }) => {
  const { ranking, payments, auth } = getState()

  const pathname = extra.LocationService.getPathname()
  const fullUrl = extra.LocationService.getFullUrl()

  const { websiteId, feature } = getWebsiteIdFromUrl(pathname)

  if (!websiteId) return

  dispatch(_select_website({ id: websiteId }))
  dispatch(RankingSetFetching({ value: true }))

  const { source, query, country, device, from, to, orderBy, page } =
    getFiltersFromUrl({
      url: fullUrl,
    })

  if (orderBy !== ranking.orderBy) {
    dispatch(RankingStoreOrderBy({ value: orderBy || "clicks" }))
  }

  const filterQuery = [
    source,
    query,
    country,
    device,
    from,
    to,
    orderBy,
    page,
    websiteId,
  ].toString()

  if (filterQuery === ranking.filter && !force) {
    dispatch(RankingSetFetching({ value: false }))
    return
  }

  const isPublic = feature === "shared"

  const response = await extra.WebsitesRepository.fetchStats({
    website: websiteId,
    filter: {
      source,
      query,
      country,
      device,
      from,
      to,
      page,
    },
    isPublic,
    orderBy,
  })

  dispatch(RankingStoreFilterQuery({ value: filterQuery }))
  dispatch(RankingSetFetching({ value: false }))

  if (response.error) {
    return dispatch(
      actions.notifications.create({
        message: response.code,
        type: "error",
      })
    )
  }

  dispatch(RankingStoreStats({ value: response.body }))
})

export const $openAndfetchByHistogram = createAsyncThunk<
  void,
  { type: "device" | "query" | "country" | "source" | "page" },
  AsyncThunkConfig
>(
  "analytics/openAndfetchByHistogram",
  async ({ type }, { dispatch, getState, extra }) => {
    const { lang } = getState()

    const pathname = extra.LocationService.getPathname()
    const fullUrl = extra.LocationService.getFullUrl()
    const [websiteId, rootPath] = pathname.split("/").reverse()

    dispatch(RankingHistogramModalSetType({ value: type }))
    dispatch(RankingHistogramModalSetFetching({ value: true }))
    dispatch(RankingHistogramModalSetToggle({ value: true }))

    const { source, query, country, device, from, to, orderBy, page } =
      getFiltersFromUrl({
        url: fullUrl,
      })

    const response = await extra.WebsitesRepository.fetchStatsHistogram({
      website: websiteId,
      filter: {
        source,
        query,
        country,
        device,
        from,
        to,
        page,
      },
      orderBy,
      page: 0,
      type,
    })

    dispatch(RankingHistogramModalSetFetching({ value: false }))

    if (response.error) {
      return dispatch(
        actions.notifications.create({
          message: response.code,
          type: "error",
        })
      )
    }

    dispatch(RankingStoreStatsHistogram({ value: response.body }))
  }
)

export const $RankingStoreFilter = createAsyncThunk<
  void,
  {
    type: "query" | "country" | "device" | "source" | "date" | "page"
    value: string
  },
  AsyncThunkConfig
>(
  "analytics/RankingStoreFilter",
  async ({ type, value }, { dispatch, getState, extra }) => {
    const { websites, lang } = getState()

    const url = new URL(extra.LocationService.getFullUrl())
    const feature = getWebsiteIdFromUrl(url.pathname).feature

    if (feature === "keywords") {
      url.pathname = normalizeUrl({
        url: `/analytics/${websites.activeWebsite}`,
        locale: lang.lang,
      })
      url.searchParams.set("orderBy", "position")
    }

    if (feature === "opportunities") {
      url.pathname = normalizeUrl({
        url: `/analytics/${websites.activeWebsite}`,
        locale: lang.lang,
      })
      url.searchParams.set("orderBy", "impressions")
    }

    if (url.searchParams.has(type) && url.searchParams.get(type) === value) {
      url.searchParams.delete(type)
    } else if (url.searchParams.has(type)) {
      url.searchParams.delete(type)
    } else {
      url.searchParams.append(type, value)
    }

    extra.LocationService.navigate(url.toString(), {
      disableScroll: feature === "analytics",
    })

    dispatch($fetch({ force: true }))
  }
)

export const $go = createAsyncThunk<void, { id: string }, AsyncThunkConfig>(
  "analytics/go",
  async ({ id }, { getState, extra }) => {
    extra.LocationService.navigate(`/dashboard/analytics/${id}`)
  }
)

export const $RankingSetDate = createAsyncThunk<
  void,
  { period: string | null; date: string | null },
  AsyncThunkConfig
>(
  "analytics/RankingSetDate",
  async ({ period, date }, { dispatch, getState, extra }) => {
    const url = new URL(extra.LocationService.getFullUrl())

    if (period === null) {
      url.searchParams.delete("period")
    } else {
      url.searchParams.set("period", period)
      url.searchParams.delete("from")
      url.searchParams.delete("to")
    }

    if (date === null) {
      url.searchParams.delete("from")
    } else {
      url.searchParams.set("from", date)
      url.searchParams.set("to", date)
    }

    extra.LocationService.navigate(url.toString(), {
      disableScroll: true,
    })

    dispatch($fetch({ force: true }))
  }
)

export const $AnalyticsSubmitCalendar = createAsyncThunk<
  void,
  { from: string; to: string },
  AsyncThunkConfig
>(
  "analytics/AnalyticsSubmitCalendar",
  async ({ from, to }, { dispatch, getState, extra }) => {
    const url = new URL(extra.LocationService.getFullUrl())

    url.searchParams.set("from", from)
    url.searchParams.set("to", to)
    url.searchParams.delete("period")

    extra.LocationService.navigate(url.toString(), {
      disableScroll: true,
    })

    dispatch($fetch({ force: true }))
    dispatch($AnalyticsCloseCalendar())
  }
)

export const $AnalyticsOpenCalendar = createAsyncThunk<
  void,
  void,
  AsyncThunkConfig
>("analytics/AnalyticsOpenCalendar", async (_, { getState, extra }) => {
  const url = new URL(extra.LocationService.getFullUrl())
  url.searchParams.set("calendar", "true")
  extra.LocationService.navigate(url.toString(), {
    disableScroll: true,
  })
})

export const $AnalyticsCloseCalendar = createAsyncThunk<
  void,
  void,
  AsyncThunkConfig
>("analytics/AnalyticsCloseCalendar", async (_, { getState, extra }) => {
  const url = new URL(extra.LocationService.getFullUrl())
  url.searchParams.delete("calendar")
  extra.LocationService.navigate(url.toString(), {
    disableScroll: true,
  })
})

export const $RankingSetOneDayDate = createAsyncThunk<
  void,
  { date: string },
  AsyncThunkConfig
>(
  "analytics/RankingSetOneDayDate",
  async ({ date }, { dispatch, getState, extra }) => {
    const { ranking } = getState()

    const url = new URL(extra.LocationService.getFullUrl())
    const from = url.searchParams.get("from")
    const to = url.searchParams.get("to")
    const period = url.searchParams.get("period")

    if (date === from && date === to) {
      extra.LocationService.navigate(
        ranking.previousFilterUrl || url.pathname,
        {
          disableScroll: true,
        }
      )

      dispatch(AnalyticsStorePreviousUrl({ value: null }))
      await dispatch($fetch({ force: true }))
      return
    }

    dispatch(AnalyticsStorePreviousUrl({ value: url.toString() }))

    if (period) url.searchParams.delete("period")
    if (from) url.searchParams.delete("from")
    if (to) url.searchParams.delete("to")

    url.searchParams.set("from", date)
    url.searchParams.set("to", date)

    extra.LocationService.navigate(url.toString(), {
      disableScroll: true,
    })

    await dispatch($fetch({ force: true }))
  }
)

export const $RankingStoreOrderBy = createAsyncThunk<
  void,
  "clicks" | "impressions" | "position" | "click_through_rate",
  AsyncThunkConfig
>(
  "analytics/RankingStoreOrderBy",
  async (orderBy, { dispatch, getState, extra }) => {
    const url = new URL(extra.LocationService.getFullUrl())
    url.searchParams.set("orderBy", orderBy)
    dispatch(RankingStoreOrderBy({ value: orderBy }))
    extra.LocationService.navigate(url.toString(), {
      disableScroll: true,
    })
    dispatch($fetch({ force: true }))
  }
)

export const $RankingStoreAnalyticsToastDataLateAccepted = createAsyncThunk<
  void,
  void,
  AsyncThunkConfig
>(
  "analytics/RankingStoreAnalyticsToastDataLateAccepted",
  async (_, { dispatch, getState, extra }) => {
    extra.LocalStorageService.store(localStorageKeys.TOASTER_ACCEPTED, "true")
    dispatch(RankingSetToastAccepted({ value: true }))
  }
)

export const $RankingFetchAnalyticsToastDataLateAccepted = createAsyncThunk<
  void,
  void,
  AsyncThunkConfig
>(
  "analytics/RankingFetchAnalyticsToastDataLateAccepted",
  async (_, { dispatch, getState, extra }) => {
    const accepted = Boolean(
      extra.LocalStorageService.get(localStorageKeys.TOASTER_ACCEPTED)
    )
    dispatch(RankingSetToastAccepted({ value: accepted }))
  }
)

export const $onPreviousPeriod = createAsyncThunk<void, void, AsyncThunkConfig>(
  "analytics/onPreviousPeriod",
  async (_, { dispatch, getState, extra }) => {
    const { websites } = getState()

    const period =
      new URL(extra.LocationService.getFullUrl()).searchParams.get("period") ||
      ""

    const { from, to } = moveDate({
      url: extra.LocationService.getFullUrl(),
      direction: "past",
      period,
    })

    const url = new URL(extra.LocationService.getFullUrl())

    url.searchParams.set("from", from)
    url.searchParams.set("to", to)

    extra.LocationService.navigate(url.toString(), {
      disableScroll: true,
    })

    dispatch($fetch({ force: true }))
  }
)

export const $onNextPeriod = createAsyncThunk<void, void, AsyncThunkConfig>(
  "analytics/onNextPeriod",
  async (_, { dispatch, getState, extra }) => {
    const { websites } = getState()

    const period =
      new URL(extra.LocationService.getFullUrl()).searchParams.get("period") ||
      ""

    const { from, to } = moveDate({
      url: extra.LocationService.getFullUrl(),
      direction: "future",
      period: period,
    })

    const url = new URL(extra.LocationService.getFullUrl())

    url.searchParams.set("from", from)
    url.searchParams.set("to", to)

    extra.LocationService.navigate(url.toString(), {
      disableScroll: true,
    })

    dispatch($fetch({ force: true }))
  }
)

export const $ActivateAnalytics = createAsyncThunk<
  void,
  void,
  AsyncThunkConfig
>("analytics/ActivateAnalytics", async (_, { dispatch, getState, extra }) => {
  const { websites, lang } = getState()

  if (!websites.activeWebsite) return
  dispatch(
    AnalyticsSetAnalyticsDiscoverModalIsFetching({
      value: true,
    })
  )

  dispatch(
    RankingSetFetching({
      value: true,
    })
  )

  await extra.WebsitesRepository.activateAnalytics({
    websiteId: websites.activeWebsite,
  })

  await dispatch(actions.websites.$fetchAll({ force: true }))

  dispatch(
    AnalyticsSetAnalyticsDiscoverModalIsFetching({
      value: false,
    })
  )

  dispatch(
    RankingSetFetching({
      value: false,
    })
  )

  extra.LocationService.navigate(
    normalizeUrl({
      url: `/analytics/${websites.activeWebsite}`,
      locale: lang.lang,
    })
  )

  await dispatch($fetch({ force: true }))
})

export const store_websites = createAction<AnalyticsWebsite[]>(
  "analytics/store_websites"
)

export const $fetch_websites = createAsyncThunk<void, void, AsyncThunkConfig>(
  "analytics/fetch_websites",
  async (_, { dispatch, getState, extra }) => {
    const { websites } = getState()

    const response = await extra.SpreadRepository.fetch_websites()

    if (response.error) return

    dispatch(store_websites(response.body))
  }
)

export const _select_website = createAction<{ id: string }>(
  "analytics/select_website"
)

export const AnalyticsToggleDimension = createAction<{
  type: "clicks" | "impressions" | "position" | "click_through_rate"
}>("analytics/AnalyticsToggleDimension")

export const $select_website = createAsyncThunk<
  void,
  { id: string },
  AsyncThunkConfig
>("analytics/select_website", async ({ id }, { dispatch, extra }) => {
  dispatch(_select_website({ id }))

  extra.LocationService.navigate(`/dashboard/analytics/${id}`)

  dispatch($fetch({ force: true }))
})

export const $exportData = createAsyncThunk<
  void,
  { type: string; filetype: "csv" | "json" },
  AsyncThunkConfig
>("analytics/export_data", async ({ type, filetype }, { getState }) => {
  const { ranking, websites } = getState()

  const data = ranking.stats[type] as RankingStatEntity[]

  const dataWithWebsiteId = data.map((item) => ({
    query: item.query,
    device: item.device,
    country: item.country,
    page: item.page,
    clicks: item.clicks,
    source: "google",
    impressions: item.impressions,
    position: item.position,
    click_through_rate: item.click_through_rate,
    previous_clicks: item.previous_clicks,
    previous_impressions: item.previous_impressions,
    previous_position: item.previous_position,
    previous_click_through_rate: item.previous_click_through_rate,
  }))

  if (!data) return

  const filename = `${websites.activeWebsite}-${type}`

  if (filetype === "csv") {
    const csv = json2csv(dataWithWebsiteId as object[]) as string

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", `${filename}.csv`)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } else if (filetype === "json") {
    const json = JSON.stringify(dataWithWebsiteId)

    const blob = new Blob([json], { type: "application/json" })
    const link = document.createElement("a")

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", `${filename}.json`)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
})
