import {
  UserEntity,
  UserToGoogleSearchConsoleWithEmailsEntity,
} from "@foudroyer/interfaces"
import { createReducer } from "@reduxjs/toolkit"
import * as actions from "./actions"

interface AuthState {
  user: UserEntity | null
  authenticated: boolean
  isFetching: boolean
  accountConnectedTo: {
    google: boolean
    yandex: boolean
    bing: boolean
  }
  isPremium: boolean
  initialised: boolean
  googleSearchAccounts: UserToGoogleSearchConsoleWithEmailsEntity[]
}

const initialState: AuthState = {
  user: null,
  authenticated: false,
  isFetching: false,
  accountConnectedTo: {
    google: true,
    yandex: false,
    bing: false,
  },
  isPremium: false,
  initialised: false,
  googleSearchAccounts: [],
}

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.AuthGoogleSearchConsoleAccountsStore, (state, action) => {
      return {
        ...state,
        googleSearchAccounts: action.payload.accounts,
      }
    })
    .addCase(actions.storeUser, (state, action) => {
      return {
        ...state,
        user: action.payload,
        authenticated: true,
      }
    })
    .addCase(actions.AuthStoreSources, (state, action) => {
      return {
        ...state,
        accountConnectedTo: action.payload,
      }
    })
    .addCase(actions.setFetching, (state, action) => {
      return {
        ...state,
        isFetching: action.payload.value,
      }
    })
    .addCase(actions.AuthSetInitialized, (state, action) => {
      return {
        ...state,
        initialised: action.payload.value,
      }
    })
    .addCase(actions.AuthLogout, () => {
      return initialState
    })
})
