import { createReducer } from "@reduxjs/toolkit"
import {
  RankingOrderByType,
  RankingStatEntity,
  RankingStatsForFrontend,
} from "../../entities/RankingWebsiteEntity"
import * as actions from "./actions"

export type AnalyticsWebsite = {
  id: string
  name: string
  icon: string
}

export interface RankingState {
  isFetching: boolean
  filter: string | null
  stats: RankingStatsForFrontend
  websites: AnalyticsWebsite[]
  selected_website_id: string | null
  orderBy: RankingOrderByType
  histogramModal: {
    type: "device" | "query" | "country" | "source" | "page"
    isOpen: boolean
    isFetching: boolean
    stats: RankingStatEntity[]
  }
  dimensions: {
    clicks: boolean
    impressions: boolean
    position: boolean
    click_through_rate: boolean
  }
  analyticsDiscoverModal: {
    isOpen: boolean
    isFetching: boolean
  }
  isFinished: boolean
  histogramView: RankingOrderByType
  analyticsToastDataLateAccepted: boolean
  analyticsComingSoonModal: {
    isOpen: boolean
  }
  previousFilterUrl: string | null
}

const initialState: RankingState = {
  isFetching: false,
  filter: null,
  websites: [],
  selected_website_id: null,
  isFinished: true,
  orderBy: "clicks",
  histogramModal: {
    type: "device",
    isOpen: false,
    isFetching: false,
    stats: [],
  },
  dimensions: {
    clicks: true,
    impressions: true,
    position: false,
    click_through_rate: false,
  },
  analyticsDiscoverModal: {
    isOpen: false,
    isFetching: false,
  },
  histogramView: "clicks",
  stats: {
    global: {
      click_through_rate: 0,
      clicks: 0,
      impressions: 0,
      position: 0,
      previous_clicks: 0,
      previous_click_through_rate: 0,
      previous_impressions: 0,
      previous_position: 0,
    },
    google: [],
    yandex: [],
    bing: [],
    query: [],
    sources: [],
    devices: [],
    date: [],
    countries: [],
    pages: [],
  },
  analyticsToastDataLateAccepted: true,
  analyticsComingSoonModal: {
    isOpen: false,
  },
  previousFilterUrl: null,
}

export const rankingReducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.store_websites, (state, action) => {
    return {
      ...state,
      websites: action.payload.map((website) => ({
        id: website.id,
        name: website.name,
        icon: website.icon,
      })),
    }
  })

  builder.addCase(actions.AnalyticsToggleDimension, (state, action) => {
    return {
      ...state,
      dimensions: {
        ...state.dimensions,
        [action.payload.type]: !state.dimensions[action.payload.type],
      },
    }
  })

  builder.addCase(actions.AnalyticsStorePreviousUrl, (state, action) => {
    return {
      ...state,
      previousFilterUrl: action.payload.value,
    }
  })

  builder.addCase(actions.RankingSetFetching, (state, action) => {
    return {
      ...state,
      isFetching: action.payload.value,
    }
  })

  builder.addCase(
    actions.AnalyticsSetAnalyticsComingSoonModalIsOpen,
    (state, action) => {
      return {
        ...state,
        analyticsComingSoonModal: {
          ...state.analyticsComingSoonModal,
          isOpen: action.payload.value,
        },
      }
    }
  )

  builder.addCase(
    actions.AnalyticsSetAnalyticsDiscoverModalIsOpen,
    (state, action) => {
      return {
        ...state,
        analyticsDiscoverModal: {
          ...state.analyticsDiscoverModal,
          isOpen: action.payload.value,
        },
      }
    }
  )

  builder.addCase(
    actions.AnalyticsSetAnalyticsDiscoverModalIsFetching,
    (state, action) => {
      return {
        ...state,
        analyticsDiscoverModal: {
          ...state.analyticsDiscoverModal,
          isFetching: action.payload.value,
        },
      }
    }
  )

  builder.addCase(actions.RankingSetHistogramView, (state, action) => {
    return {
      ...state,
      histogramView: action.payload.value,
    }
  })

  builder.addCase(actions.RankingStoreFilterQuery, (state, action) => {
    return {
      ...state,
      filter: action.payload.value,
    }
  })

  builder.addCase(actions.RankingStoreOrderBy, (state, action) => {
    return {
      ...state,
      orderBy: action.payload.value,
      histogramView: action.payload.value,
    }
  })

  builder.addCase(actions.RankingStoreStats, (state, action) => {
    return {
      ...state,
      stats: action.payload.value,
    }
  })

  builder.addCase(actions.AnalyticsStoreIsFinishedStatus, (state, action) => {
    return {
      ...state,
      isFinished: action.payload.value,
    }
  })

  builder.addCase(actions.RankingHistogramModalSetType, (state, action) => {
    return {
      ...state,
      histogramModal: {
        ...state.histogramModal,
        type: action.payload.value,
      },
    }
  })

  builder.addCase(actions.RankingHistogramModalSetFetching, (state, action) => {
    return {
      ...state,
      histogramModal: {
        ...state.histogramModal,
        isFetching: action.payload.value,
      },
    }
  })

  builder.addCase(actions.RankingHistogramModalSetToggle, (state, action) => {
    return {
      ...state,
      histogramModal: {
        ...state.histogramModal,
        isOpen: action.payload.value,
      },
    }
  })

  builder.addCase(actions.RankingStoreStatsHistogram, (state, action) => {
    return {
      ...state,
      histogramModal: {
        ...state.histogramModal,
        stats: action.payload.value,
      },
    }
  })

  builder.addCase(actions._select_website, (state, action) => {
    return {
      ...state,
      selected_website_id: action.payload.id,
    }
  })
})
