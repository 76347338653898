import axios from "axios"
import { localStorageKeys } from "../constants/localStorageKeys"
import { IApiResponse, IRepositoryResponse } from "../interfaces/IApiResponse"
import { IGlobalApiService } from "../interfaces/IGlobalApiService"
import { ILocalStorageService } from "../interfaces/ILocalStorageService"
const PRODUCTION_ENDPOINT = "https://coolify.foudroyer.com"
const DEVELOPMENT_ENDPOINT = "http://localhost:3000"

export class GlobalApiService implements IGlobalApiService {
  constructor(private localStorageService: ILocalStorageService) {
    axios.defaults.validateStatus = function (status) {
      return status < 500
    }
  }

  private endpoint: string =
    process.env.NODE_ENV === "production"
      ? PRODUCTION_ENDPOINT
      : DEVELOPMENT_ENDPOINT

  async get<T>(url: string): Promise<IRepositoryResponse<T>> {
    const headers = {
      Authorization:
        "Bearer " + this.localStorageService.get(localStorageKeys.TOKEN_KEY),
    }

    const response = await axios.get<IApiResponse<T>>(
      `${this.endpoint}${url}`,
      {
        headers,
        validateStatus: (status) => true,
      }
    )

    if (response.data.statusCode === 400) {
      return { error: true, code: response.data.message }
    }

    // @ts-ignore
    return { error: false, body: response.data }
  }

  async post<T>(url: string, data: any): Promise<IRepositoryResponse<T>> {
    const response = await axios.post<IApiResponse<T>>(
      `${this.endpoint}${url}`,
      data,
      {
        headers: {
          Authorization:
            "Bearer " +
            this.localStorageService.get(localStorageKeys.TOKEN_KEY),
        },
      }
    )

    if (response.data.statusCode === 400) {
      return { error: true, code: response.data.message }
    }

    // @ts-ignore
    return { error: false, body: response.data }
  }

  async put<T>(url: string, data: any): Promise<IRepositoryResponse<T>> {
    const response = await axios.put<IApiResponse<T>>(
      `${this.endpoint}${url}`,
      data,
      {
        headers: {
          Authorization:
            "Bearer " +
            this.localStorageService.get(localStorageKeys.TOKEN_KEY),
        },
      }
    )

    if (response.data.statusCode === 400) {
      return { error: true, code: response.data.message }
    }

    // @ts-ignore
    return { error: false, body: response.data }
  }

  async delete<T>(url: string): Promise<IRepositoryResponse<T>> {
    const response = await axios.delete<IApiResponse<T>>(
      `${this.endpoint}${url}`,
      {
        headers: {
          Authorization:
            "Bearer " +
            this.localStorageService.get(localStorageKeys.TOKEN_KEY),
        },
      }
    )

    if (response.data.statusCode === 400) {
      return { error: true, code: response.data.message }
    }

    // @ts-ignore
    return { error: false, body: response.data }
  }
}
