import {
  ErrorEntity,
  PaymentPlansEntity,
  UserEntity,
  UserToGoogleSearchConsoleWithEmailsEntity,
} from "@foudroyer/interfaces"
import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import delay from "delay"
import {
  getCallbackUrl,
  GET_GOOGLE_AUTH_URL,
} from "../../constants/authentication"
import { localStorageKeys } from "../../constants/localStorageKeys"
import { normalizeUrl } from "../../utils/normalizeUrl"
import { actions } from "../actions"
import { AsyncThunkConfig } from "../store"

export const storeUser = createAction<UserEntity>("AUTH_STORE_USER")

export const setFetching = createAction<{ value: boolean }>("AUTH_SET_FETCHING")

export const AuthSetInitialized =
  createAction<{ value: boolean }>("AuthSetInitialized")

export const AuthLogout = createAction("AuthLogout")

export const AuthStoreSources = createAction<{
  google: boolean
  yandex: boolean
  bing: boolean
}>("AuthStoreSources")

const _getUserInfoAndStoreIt = createAsyncThunk<any, void, AsyncThunkConfig>(
  "auth/getUserInfoAndStoreIt",
  async (_, { dispatch, getState, extra: di }) => {
    try {
      const [user, sources] = await Promise.all([
        di.AuthRepository.getUserInfo(),
        di.AuthRepository.getSources(),
      ])

      if (user.error === false) {
        dispatch(storeUser(user.body))

        if (!sources.error) {
          dispatch(
            AuthStoreSources({
              google: sources.body.google,
              yandex: sources.body.yandex,
              bing: sources.body.bing,
            })
          )
        }

        dispatch(actions.payments.$fetchPaymentsInfo())
      }
    } catch (e) {
      dispatch(
        actions.notifications.create({
          type: "error",
          // @ts-ignore
          message: e.message,
        })
      )
    }
  }
)

export const $logout = createAsyncThunk<void, void, AsyncThunkConfig>(
  "auth/logout",
  async (_, { dispatch, getState, extra: di }) => {
    const { lang } = getState()

    di.AnalyticsService.send({
      action: "logout",
      category: "authentication",
    })
    dispatch(AuthLogout())
    di.AnalyticsService.logout()
    di.LocalStorageService.remove(localStorageKeys.TOKEN_KEY)
    di.LocationService.navigate(normalizeUrl({ url: "/", locale: lang.lang }))
  }
)

const getLanguageFromNavigator = () => {
  if (!navigator) return "en"
  if (!navigator.language) return "en"
  return navigator.language?.split("-")[0] || "en"
}

export const $goToAuthentication = createAsyncThunk<
  void,
  { redirection?: string } | undefined,
  AsyncThunkConfig
>(
  "auth/goToAuthentication",
  async (params, { dispatch, getState, extra: di }) => {
    if (params?.redirection) {
      di.LocalStorageService.store(
        localStorageKeys.REDIRECT_URL_AFTER_LOGIN,
        params.redirection
      )
    }

    di.AnalyticsService.send({
      category: "authentication",
      action: "trying_to_connect",
      data: {
        state: "go_to_authentication",
      },
    })

    await delay(500)

    di.LocationService.navigate(GET_GOOGLE_AUTH_URL())
  }
)

const _sendAuthenticationEventsIfAuthenticated = createAsyncThunk<
  void,
  void,
  AsyncThunkConfig
>(
  "auth/_sendAuthenticationEventsIfAuthenticated",
  async (_, { dispatch, getState, extra: di }) => {
    const { auth } = getState()

    if (!auth.user) return

    di.AnalyticsService.send({
      action: "login",
      category: "authentication",
    })

    di.AnalyticsService.authenticate({
      id: auth.user.id,
      created_at: auth.user.created_at,
    })
  }
)

const _redirectAfterLogin = createAsyncThunk<void, void, AsyncThunkConfig>(
  "auth/_redirectAfterLogin",
  async (_, { dispatch, getState, extra: di }) => {
    if (di.LocalStorageService.get(localStorageKeys.REDIRECT_URL_AFTER_LOGIN)) {
      di.LocationService.navigate(
        `${di.LocalStorageService.get(
          localStorageKeys.REDIRECT_URL_AFTER_LOGIN
        )}`
      )

      di.LocalStorageService.remove(localStorageKeys.REDIRECT_URL_AFTER_LOGIN)
    } else {
      di.LocationService.refresh("/dashboard/")
    }
  }
)

export const $authenticateWithGoogleCode = createAsyncThunk<
  void,
  { code: string; redirect?: string },
  AsyncThunkConfig
>(
  "auth/authenticateWithGoogleCode",
  async (params, { dispatch, getState, extra: di }) => {
    const response = await di.AuthRepository.postAuthenticationCode({
      language: getLanguageFromNavigator(),
      callbackUrl: getCallbackUrl("google"),
      code: params.code,
      type: "google",
    })

    if (
      response.error === true &&
      response.code === ErrorEntity.GOOGLE_AUTH_SCOPE_NOT_FOUND
    ) {
      di.AnalyticsService.send({
        category: "authentication",
        action: "trying_to_connect",
        data: {
          state: "scope-not-found",
        },
      })

      di.LocationService.refresh("/authentication/google/scope-not-found")

      return
    }

    if (response.error === true) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: response.code,
        })
      )
    }

    di.LocalStorageService.store(localStorageKeys.TOKEN_KEY, response.body)

    await dispatch(_getUserInfoAndStoreIt())
    await dispatch(_sendAuthenticationEventsIfAuthenticated())
    await dispatch(_redirectAfterLogin())
  }
)

export const AuthGoogleSearchConsoleAccountsStore = createAction<{
  accounts: UserToGoogleSearchConsoleWithEmailsEntity[]
}>("AuthGoogleSearchConsoleAccountsStore")

export const $addGoogleSearchAccount = createAsyncThunk<
  void,
  { disableRedirection?: boolean } | undefined,
  AsyncThunkConfig
>(
  "auth/addGoogleSearchAccount",
  async (params, { dispatch, getState, extra: di }) => {
    const { lang, payments } = getState()
    const actualPlan = payments.actualIndexationPlan?.plan

    if (
      actualPlan !== PaymentPlansEntity["enterprise"] &&
      actualPlan !== PaymentPlansEntity["indexation/teams"]
    ) {
      return dispatch(
        actions.payments.$PaymentsOpenModal({
          value: true,
          type: "indexation",
          source: "multi-google-search",
        })
      )
    }

    const response = await di.AuthRepository.addGoogleSearchAccount({
      language: getLanguageFromNavigator(),
    })

    if (
      response.error === true &&
      response.code === ErrorEntity.GOOGLE_AUTH_SCOPE_NOT_FOUND
    ) {
      return dispatch(actions.modal.$openScopeNotFoundModal())
    }

    if (response.error === true) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: response.code,
          timeout: 10000,
        })
      )
    }

    dispatch(actions.auth.$GoogleSearchAccountFetchParents())

    dispatch(
      actions.notifications.create({
        type: "success",
      })
    )
  }
)

export const $GoogleSearchAccountFetchParents = createAsyncThunk<
  any,
  void,
  AsyncThunkConfig
>(
  "auth/GoogleSearchAccountFetchParents",
  async (_, { dispatch, getState, extra: di }) => {
    const { auth } = getState()

    if (!auth.authenticated) return false

    const response = await di.AuthRepository.GoogleSearchAccountGetParents()

    if (response.error) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: response.code,
        })
      )
    }

    return dispatch(
      actions.auth.AuthGoogleSearchConsoleAccountsStore({
        accounts: response.body,
      })
    )
  }
)

export const $GoogleSearchAccountDelete = createAsyncThunk<
  boolean,
  { id: UserToGoogleSearchConsoleWithEmailsEntity["id"] },
  AsyncThunkConfig
>(
  "auth/GoogleSearchAccountDelete",
  async (params, { dispatch, getState, extra: di }) => {
    const { auth } = getState()

    if (!auth.authenticated) return false

    const response = await di.AuthRepository.GoogleSearchAccountDelete(params)

    if (response.error) {
      dispatch(
        actions.notifications.create({
          type: "error",
          message: response.code,
        })
      )
    }

    dispatch(
      actions.notifications.create({
        type: "success",
      })
    )
    dispatch(actions.auth.$GoogleSearchAccountFetchParents())

    return true
  }
)

export const $isAuthenticated = createAsyncThunk<void, void, AsyncThunkConfig>(
  "auth/isAuthenticated",
  async (_, { dispatch }) => {
    dispatch(setFetching({ value: true }))

    await dispatch(_getUserInfoAndStoreIt())

    dispatch(AuthSetInitialized({ value: true }))
    dispatch(setFetching({ value: false }))
  }
)

export const $isAuthenticatedOrRedirect = createAsyncThunk<
  void,
  void,
  AsyncThunkConfig
>("auth/isAuthenticatedOrRedirect", async (_, { dispatch, getState }) => {
  await dispatch(actions.auth.$isAuthenticated())

  const { auth } = getState()

  if (auth.authenticated) return

  try {
    // startHotjar()
  } catch (e) {}
})
