import { ErrorEntity } from "@foudroyer/interfaces"
import { RankingStatsForFrontend } from "../entities/RankingWebsiteEntity"
import { IRepositoryResponse } from "../interfaces/IApiResponse"
import { ISpreadRepository } from "../interfaces/ISpreadRepository"
import { StatsResponse } from "../interfaces/IWebsitesRepository"
import { AnalyticsWebsite } from "../redux/analytics/reducers"
import { ApiService } from "../services/ApiService"
import { getFavicon } from "../utils/getFavicon"

export class ApiSpreadRepository implements ISpreadRepository {
  constructor(private apiService: ApiService) {}

  async fetch(): Promise<StatsResponse> {
    try {
      const response = await this.apiService.get<RankingStatsForFrontend>(
        `/spread`
      )

      if (response.data.statusCode === 400) {
        return { error: true, code: response.data.message }
      }

      return { error: false, body: response.data }
    } catch (error) {
      return { error: true, code: ErrorEntity.UNKNOWN_ERROR }
    }
  }

  async fetchLadder(): Promise<any> {
    try {
      const response = await this.apiService.get<any>(`/spread/leaderboard`)

      if (response.data.statusCode === 400)
        return { error: true, code: response.data.message }

      return { error: false, body: response.data }
    } catch (error) {
      return { error: true, code: ErrorEntity.UNKNOWN_ERROR }
    }
  }

  async fetch_websites(): Promise<IRepositoryResponse<AnalyticsWebsite[]>> {
    try {
      type Response = {
        id: string
        is_favorite: boolean
        search_console_domain: string
      }

      const response = await this.apiService.$get<Response[]>(
        `/analytics/websites`
      )

      if (response.statusCode === 400) {
        return { error: true, code: response.message }
      }

      return {
        error: false,
        body: response.body.map(
          (website): AnalyticsWebsite => ({
            id: website.id,
            name: website.id,
            icon: getFavicon(website.id),
          })
        ),
      }
    } catch (error) {
      return { error: true, code: ErrorEntity.UNKNOWN_ERROR }
    }
  }
}
