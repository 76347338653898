import { IRepositoryResponse } from "../interfaces/IApiResponse"
import { CrawlResponse, ISitemapsService } from "../interfaces/ISitemapsService"

export class InMemorySitemapsService implements ISitemapsService {
  async fetch(params: {
    url: string
  }): Promise<IRepositoryResponse<CrawlResponse>> {
    return {
      error: false,
      body: {
        statusCode: 200,
        pages: [],
        type: "sitemap",
        sitemaps: [],
        numberTotalOfPages: 0,
        url: params.url,
      },
    }
  }
}
