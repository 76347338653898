import {
  RankingOrderByType,
  RankingStatsForFrontend,
} from "../../entities/RankingWebsiteEntity"
import { createFakeDataForSpread } from "../../utils/spread/create-fake-data"
import * as types from "./types"

export interface SpreadState {
  isFetching: boolean
  stats: RankingStatsForFrontend
  type: RankingOrderByType
  isRealUserData: boolean
  dimensions: {
    clicks: boolean
    impressions: boolean
    position: boolean
    click_through_rate: boolean
  }
  ladder: any[]
  sources: {
    google: {
      clicks: number
      impressions: number
      activated: boolean
    }
    bing: {
      clicks: number
      impressions: number
      activated: boolean
    }
    yandex: {
      clicks: number
      impressions: number
      activated: boolean
    }
  }
  websites: Array<{
    id: string
    clicks: number
    impressions: number
    timeline: {
      clicks: number
      impressions: number
      date: string
    }[]
  }>
}

const initialState: SpreadState = {
  isFetching: false,
  stats: createFakeDataForSpread(),
  isRealUserData: false,
  type: "clicks",
  dimensions: {
    clicks: true,
    impressions: true,
    position: false,
    click_through_rate: false,
  },
  ladder: [],
  websites: [],
  sources: {
    google: {
      clicks: 0,
      impressions: 0,
      activated: false,
    },
    bing: {
      clicks: 0,
      impressions: 0,
      activated: false,
    },
    yandex: {
      clicks: 0,
      impressions: 0,
      activated: false,
    },
  },
}

export function spreadReducer(
  state = initialState,
  action: types.SpreadActionTypes
): SpreadState {
  if (action.type === types.SpreadSetFetching) {
    return {
      ...state,
      isFetching: action.payload.value,
    }
  }

  if (action.type === types.ToggleDimension) {
    return {
      ...state,
      dimensions: {
        ...state.dimensions,
        [action.payload.type]: !state.dimensions[action.payload.type],
      },
    }
  }

  if (action.type === types.SpreadStoreStats) {
    return {
      ...state,
      stats: action.payload,
      isRealUserData: true,
    }
  }

  if (action.type === types.SpreadStoreLadder) {
    return {
      ...state,
      ladder: action.payload,
    }
  }

  if (action.type === types.SpreadStoreWebsites) {
    return {
      ...state,
      websites: action.payload,
    }
  }

  if (action.type === types.SpreadStoreSources) {
    return {
      ...state,
      sources: action.payload,
    }
  }

  return state
}
