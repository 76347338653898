import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "./actions"
import { rankingReducer } from "./analytics/reducers"
import { authReducer } from "./auth/reducers"
import { darkModeReducer } from "./dark-mode/reducers"
import { diReducer } from "./di/reducers"
import { DiInjectable } from "./di/types"
import { indexationReducer } from "./indexation/reducers"
import { indexation_website_bad_setup_on_google_modal_reducer } from "./indexation_website_bad_setup_on_google_modal/reducers"
import { keywordsReducer } from "./keywords/reducers"
import { langReducer } from "./lang/reducers"
import { loaderReducer } from "./loader/reducers"
import { logsReducer } from "./logs/reducers"
import { modalReducer } from "./modal/reducers"
import { notificationsReducer } from "./notifications/reducers"
import { opportunitiesReducer } from "./opportunities/reducers"
import { paymentsReducer } from "./payments/reducers"
import { roastReducer } from "./roast/reducers"
import { searchGptKeywordsReducer } from "./search-gpt/reducers"
import { sitemapsReducer } from "./sitemaps/reducers"
import { spreadReducer } from "./spread/reducers"
import { statsReducer } from "./stats/reducers"
import { websitesReducer } from "./websites/reducers"

export const reducers = combineReducers({
  roast: roastReducer,
  notifications: notificationsReducer,
  spread: spreadReducer,
  lang: langReducer,
  auth: authReducer,
  di: diReducer,
  ranking: rankingReducer,
  indexation: indexationReducer,
  websites: websitesReducer,
  modal: modalReducer,
  loader: loaderReducer,
  darkMode: darkModeReducer,
  payments: paymentsReducer,
  keywords: keywordsReducer,
  opportunities: opportunitiesReducer,
  stats: statsReducer,
  logs: logsReducer,
  sitemaps: sitemapsReducer,
  indexation_website_bad_setup_on_google_modal:
    indexation_website_bad_setup_on_google_modal_reducer,
  searchGptKeywords: searchGptKeywordsReducer,
})

export const init = (initialState = {}, di: DiInjectable) => {
  const store = configureStore({
    reducer: reducers,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: di,
        },
      }),
  })

  store.dispatch(actions.di.register(di))

  return { store }
}

export type RootState = ReturnType<typeof reducers>
export type Dispatch = ReturnType<typeof init>["store"]["dispatch"]
export type AsyncThunkConfig = {
  state: RootState
  dispatch: Dispatch
  extra: DiInjectable
}

export const useAppDispatch = () => useDispatch<Dispatch>()
export const useAppSelector: <T>(selector: (state: RootState) => T) => T =
  useSelector
