import { createAsyncThunk } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import weekOfYear from "dayjs/plugin/weekOfYear"
import delay from "delay"
import { domToJpeg } from "modern-screenshot"
import { ThunkAction } from "redux-thunk"
import { actions } from "../actions"
import { AsyncThunkConfig, RootState } from "../store"
import * as types from "./types"

dayjs.extend(weekOfYear)

export const SpreadSetFetching = (
  payload: types.SpreadSetFetchingAction["payload"]
): types.SpreadActionTypes => ({
  type: types.SpreadSetFetching,
  payload,
})

export const ToggleDimension = (
  payload: types.ToggleDimensionAction["payload"]
): types.SpreadActionTypes => ({
  type: types.ToggleDimension,
  payload,
})

export const SpreadStoreWebsites = (
  payload: types.SpreadStoreWebsitesAction["payload"]
): types.SpreadActionTypes => ({
  type: types.SpreadStoreWebsites,
  payload,
})

export const SpreadStoreSources = (
  payload: types.SpreadStoreSourcesAction["payload"]
): types.SpreadActionTypes => ({
  type: types.SpreadStoreSources,
  payload,
})

export const SpreadStoreStats = (
  payload: types.SpreadStoreStatsAction["payload"]
): types.SpreadActionTypes => ({
  type: types.SpreadStoreStats,
  payload,
})

export const SpreadStoreLadder = (
  payload: types.SpreadStoreLadderAction["payload"]
): types.SpreadActionTypes => ({
  type: types.SpreadStoreLadder,
  payload,
})

export const $fetchIfConnected =
  (): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
    const { di, spread, payments, auth } = getState()
    if (!auth.authenticated) await dispatch(actions.auth.$isAuthenticated())
    dispatch(actions.spread.$fetch())
  }

export const $fetch =
  (): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
    const { di, spread, auth } = getState()

    if (spread.isFetching) return false
    if (!auth.user) return false

    dispatch(actions.spread.SpreadSetFetching({ value: true }))

    const response = await di.SpreadRepository.fetch()

    dispatch(actions.spread.SpreadSetFetching({ value: false }))

    di.AnalyticsService.send({
      category: "show-off",
      action: "fetch",
    })

    if (response.error) return true

    dispatch(actions.spread.SpreadStoreStats(response.body.stats))
    dispatch(actions.spread.SpreadStoreWebsites(response.body.websites))
    dispatch(actions.spread.SpreadStoreSources(response.body.sources))
  }

export const $authenticate =
  (source: "bing" | "yandex"): ThunkAction<any, RootState, any, any> =>
  async (dispatch, getState) => {
    const { di } = getState()

    if (source === "bing") {
      const response = await di.AuthRepository.authenticateWithBing()
      if (response.error)
        return dispatch(
          actions.notifications.create({
            message: response.code,
            type: "error",
          })
        )
    } else if (source === "yandex") {
      const response = await di.AuthRepository.authenticateWithYandex()

      if (response.error)
        return dispatch(
          actions.notifications.create({
            message: response.code,
            type: "error",
          })
        )
    }

    await dispatch(actions.spread.$fetch())
  }

export const $fetchLadder =
  (): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
    const { di, spread, payments, auth } = getState()

    const response = await di.SpreadRepository.fetchLadder()

    dispatch(actions.spread.SpreadStoreLadder(response.body))
  }

export const $fetchOrConnect =
  (): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
    const { di, spread, payments, auth } = getState()

    if (auth.user) {
      dispatch(actions.spread.$fetch())
    } else {
      await dispatch(
        actions.auth.$goToAuthentication({
          redirection: "/dashboard/show-off/",
        })
      )

      if (getState().auth.user) return dispatch(actions.spread.$fetch())
    }
  }

export const $RankingStoreOrderBy =
  (
    orderBy: "clicks" | "impressions" | "position" | "click_through_rate"
  ): ThunkAction<any, RootState, any, any> =>
  (dispatch, getState) => {
    const { di } = getState()

    const url = new URL(di.LocationService.getFullUrl())

    url.searchParams.set("orderBy", orderBy)

    di.LocationService.navigate(url.toString(), {
      disableScroll: true,
    })
  }

export const $download = createAsyncThunk<
  void,
  { id: string; filename?: string },
  AsyncThunkConfig
>("spread/download", async (params, { extra }) => {
  const element = document.querySelector(`#${params.id}`)

  if (!element) return

  await delay(1000)

  domToJpeg(element, {
    quality: 100,
    scale: 2,
  }).then((dataUrl) => {
    const link = document.createElement("a")
    link.download = `${params.filename || "screenshot"}.jpeg`
    link.href = dataUrl
    link.click()

    extra.AnalyticsService.send({
      category: "show-off",
      action: "download",
    })
  })
})
