exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-analytics-tsx": () => import("./../../../src/pages/analytics/[...].tsx" /* webpackChunkName: "component---src-pages-analytics-tsx" */),
  "component---src-pages-authentication-google-callback-add-search-console-index-tsx": () => import("./../../../src/pages/authentication/google/callback-add-search-console/index.tsx" /* webpackChunkName: "component---src-pages-authentication-google-callback-add-search-console-index-tsx" */),
  "component---src-pages-authentication-google-callback-index-tsx": () => import("./../../../src/pages/authentication/google/callback/index.tsx" /* webpackChunkName: "component---src-pages-authentication-google-callback-index-tsx" */),
  "component---src-pages-authentication-google-scope-not-found-index-tsx": () => import("./../../../src/pages/authentication/google/scope-not-found/index.tsx" /* webpackChunkName: "component---src-pages-authentication-google-scope-not-found-index-tsx" */),
  "component---src-pages-dashboard-analytics-tsx": () => import("./../../../src/pages/dashboard/analytics/[...].tsx" /* webpackChunkName: "component---src-pages-dashboard-analytics-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-indexation-index-tsx": () => import("./../../../src/pages/dashboard/indexation/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-indexation-index-tsx" */),
  "component---src-pages-dashboard-indexation-tsx": () => import("./../../../src/pages/dashboard/indexation/[...].tsx" /* webpackChunkName: "component---src-pages-dashboard-indexation-tsx" */),
  "component---src-pages-dashboard-show-off-tsx": () => import("./../../../src/pages/dashboard/show-off/[...].tsx" /* webpackChunkName: "component---src-pages-dashboard-show-off-tsx" */),
  "component---src-pages-dashboard-sitemapy-tsx": () => import("./../../../src/pages/dashboard/sitemapy/[...].tsx" /* webpackChunkName: "component---src-pages-dashboard-sitemapy-tsx" */),
  "component---src-pages-dashboard-user-index-tsx": () => import("./../../../src/pages/dashboard/user/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-user-index-tsx" */),
  "component---src-pages-keywords-tsx": () => import("./../../../src/pages/keywords/[...].tsx" /* webpackChunkName: "component---src-pages-keywords-tsx" */),
  "component---src-pages-logs-tsx": () => import("./../../../src/pages/logs/[...].tsx" /* webpackChunkName: "component---src-pages-logs-tsx" */),
  "component---src-pages-opportunities-tsx": () => import("./../../../src/pages/opportunities/[...].tsx" /* webpackChunkName: "component---src-pages-opportunities-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect/[...].tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-roast-dashboard-tsx": () => import("./../../../src/pages/roast/dashboard/[...].tsx" /* webpackChunkName: "component---src-pages-roast-dashboard-tsx" */),
  "component---src-pages-search-gpt-tsx": () => import("./../../../src/pages/search-gpt/[...].tsx" /* webpackChunkName: "component---src-pages-search-gpt-tsx" */),
  "component---src-pages-shared-tsx": () => import("./../../../src/pages/shared/[...].tsx" /* webpackChunkName: "component---src-pages-shared-tsx" */),
  "component---src-templates-administration-tsx": () => import("./../../../src/templates/administration.tsx" /* webpackChunkName: "component---src-templates-administration-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-upsell-tsx": () => import("./../../../src/templates/upsell.tsx" /* webpackChunkName: "component---src-templates-upsell-tsx" */)
}

